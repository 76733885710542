
/*------------SigninSignup css*/
html,
body {
  margin: 0px;
  height: 100%;
  width: 100%;
}
.container {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.div1 {
  background: transparent url("./Images/life.png") 0% 0% no-repeat;
  background-size: cover;
  border: 1px solid black;
  height: 100vh;
  width: 832px;
}
.title {
  margin-left: 70px;
  margin-top: 131px;
}
.p1 {
  text-align: left;
  font: normal normal 900 90px/116px Avenir;
  letter-spacing: 0px;
  color: #c4b4f8;
  opacity: 1;
  margin-bottom: 0;
}
.p2 {
  margin-top: 0;
  text-align: left;
  font: normal normal normal 30px/48px Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}
.p3 {
  margin-top: 0;
  text-align: left;
  font: normal normal normal 30px/48px Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: lowercase;
  opacity: 1;
  margin-bottom: 150px;
}
.buttons {
  margin-top: 110px;
  margin-left: 70px;
  display: flex;
  align-content: flex-start;
}
.buttons > button {
  border: hidden;
  background: transparent;
  color: #ffffff;
  font-size: 15px;
  text-decoration: underline;
  /* border-bottom-style: groove; */
  cursor: pointer;
}
.div2 {
  border: 1px solid black;
  height: 100vh;
  width: 608px;
  background: #0f121ff5 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*----------------------------------------------Signup------------------------------------------------*/
.regis {
  text-align: center;
}
.regis > h1 {
  text-align: center;
  letter-spacing: 0.41px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}
.regis > p {
  margin-top: 8px;
  text-align: center;
  letter-spacing: 0.34px;
  color: #ffffff;
  opacity: 1;
}
/* .profilepic {
  margin: auto;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  border: 1px solid rgb(241, 206, 206);
} */
.profilepic {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background: #282c34;
  overflow: hidden;
  margin: 0 auto;
}
.profilepic input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.profilepic::before {
  content: "+";
  display: block;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: white;
}
.profilepic > input {
  margin: auto;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #282c34;
  background: #282c34;
}
.inp > input {
  padding: 6px;
  margin-top: 20px;
  width: 220px;
  background: #0f121ff5 0% 0% no-repeat padding-box;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  color: white;
}
.btnR {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 110px;
  height: 30px;
  background: #c4b4f8 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 0.79;
  cursor: pointer;
}

.btnR:disabled {
  cursor: not-allowed;
}

/*--------------------------------SignIn----------------------------------------------------*/

.login-container1 {
  height: 575px;
  display: flex;
  align-content: center;
  text-align: center;
}
.login-container {
  margin: auto;
}
.login-container > h1 {
  text-align: center;
  letter-spacing: 0.41px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}
.login-container > p {
  margin-top: 8px;
  text-align: center;
  letter-spacing: 0.34px;
  color: #ffffff;
  opacity: 1;
}

.S-inp > input {
  padding: 6px;
  margin-top: 20px;
  width: 220px;
  background: #0f121ff5 0% 0% no-repeat padding-box;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  color: white;
}

.btnS {
  margin-top: 30px;
  margin-bottom: 49px;
  width: 110px;
  height: 30px;
  background: #c4b4f8 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 0.79;
  cursor: pointer;
}

.btnS:disabled {
  cursor: not-allowed;
}

/*---------------------------------VideoUploads-------------------------------*/
.V-container {
  /* border: 1px solid black;
  background: #000000 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 760px;
  margin: auto;
  margin-top: 40px; */
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.V-container2 {
  /* margin: 20px; */
  background-color: black;
  top: 330px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 800px;
  border-radius: 5px;
  z-index: 30000000;
  border: 1px solid white;
  text-align: center;
}

.V-Title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.V-Title > p {
  /* margin-top: 0px; */
  font-size: 20px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding-left: 20px;
}
.V-Title > button {
  height: 25px;
  background: #0a0a0a 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #ffffff;
  margin-right: 20px;
  cursor: pointer;
}

.drag-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin:20px; */
  background: #4b4b5f 0% 0% no-repeat padding-box;
  border: 1px solid black;
  border-radius: 5px;
  height: 170px;
  opacity: 1;
  margin: 10px 50px;
}
.dd-image {
  height: 70px;
  width: 70px;
  margin-bottom: 10px;
}
.drag-drop > p {
  margin-top: 5px;
  text-align: center;
  font-size: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 5px;
}
.F-inp {
  position: relative;
  opacity: 0;
  cursor: pointer;
  margin-top: 0px;
  margin-left: -75px;
  margin-bottom: -20px;
}
.F-inp:hover {
  cursor: pointer;
}
/* #p2:hover{
  color:aqua;
} */
.F-inp > p {
  position: absolute;
}
.abc {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
}
.abc > p {
  text-align: left;
  margin-bottom: 0px;
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: 20px;
  margin-top: 0;
}

.abc > input {
  background-color: #0c0c0c;
  color: #ffffff;
  height: 20px;
  width: 90%;
  border: 1px solid #c4bdbd;
  opacity: 10;
  margin-left: 20px;
  border-radius: 5px;
  padding: 5px 10px;
}
.abc > textarea {
  background-color: #0c0c0c;
  opacity: 10;
  color: #ffffff;
  margin-top: 5px;
  width: 90%;
  height: 50px;
  padding: 10px;
  margin-left: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.category {
  display: flex;
  justify-content: space-around;
}
.category > div > p {
  text-align: left;
  font: normal normal normal 12px/16px Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.7;
}
.category > div > select {
  /* transform: matrix(-1, 0, 0, -1, 0, 0); */
  /* appearance: none;
background-image: url("https://www.pngkit.com/png/full/273-2739733_white-drop-down-arrow.png");
  background-repeat: no-repeat; */
  border: hidden;
  background-color: #000000;
  text-align: left;
  font: normal normal medium 16px/22px Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.Save {
  margin: 20px auto;
  width: 110px;
  height: 39px;
  background: #c4b4f8 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 0.79;
  cursor: pointer;
}

.Save:disabled {
  cursor: not-allowed;
}

/*----------------------------------Nav Bar---------------------------------------*/
.logo {
  top: 18px;
  left: 66px;
  width: 63px;
  height: 28px;
  /* UI Properties */
  text-align: left;
  font: normal normal 900 28px/34px Avenir;
  letter-spacing: 0px;
  color: #c4b4f8;
  opacity: 1;
}

.navigation-bar {
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 20px; */
  background-color: #f8f8f8;
  /* width: 1440px; */
  height: 60px;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #ffffff00;
  opacity: 1;
}

.search-bar {
  flex: 1;
  text-align: center;
}

.search-bar > input {
  width: 80%;
  height: 25px;
  color: #ffffff;
  background: #191919 0% 0% no-repeat padding-box;
  border: 1px solid #9d9c9c;
  padding-left: 20px;
  border-radius: 21px;
  opacity: 1;
}

.user-links {
  display: flex;
  gap: 5px;
}
.user-links > p,
span {
  color: white;
}
.user-links > span {
  align-items: center;
  margin-top: 15px;
}

/*----------------------HomePage-----------------------*/
.Image {
  width:100%;
  height: auto;
  position: relative;
}

.Image > video {
  width: 100%;
  height: 100%;
}

.video-title {
  position: absolute;
  top: 200px;
  left: 50px;
  color: white;
  font: normal normal 900 49px/60px Avenir;
}

.video-data {
  display: flex;
  position: absolute;
  top: 300px;
  left: 50px;
  color: #d6d0d0;
  font: normal normal normal 14px/19px Avenir;
  gap: 25px;
}

.publisher {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  color: white;
  top: 370px;
  left: 50px;
}

.publisher-img {
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 50%;
}

/* .Image img {
  left: 0px;
  position: absolute;
  min-width: 100%;
  height: 334px;
  background: #000000 0% 0% no-repeat padding-box;
} */

#filmName {
  top: 186px;
  left: 68px;
  width: 397px;
  width: 440px;
  height: 127px;
  text-align: left;
  font: normal normal 600 39px/40px Avenir;
  letter-spacing: 0px;
  opacity: 1;

  position: absolute;
  color: #fff;
  padding: 10px;
}

#details .date {
  top: 290px;
  left: 68px;
  width: 76px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  position: absolute;
  color: #fff;
  padding: 10px;
}

.length {
  position: absolute;
  color: #fff;
  padding: 10px;

  top: 290px;
  left: 175px;
  width: 49px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.views {
  top: 290px;
  left: 255px;
  width: 63px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  position: absolute;
  color: #fff;
  padding: 10px;
}

.profile img {
  top: 336px;
  left: 78px;
  width: 35px;
  height: 35px;
  background: transparent 0% 0% no-repeat padding-box;
  /* border: 1px solid #FFFFFF; */
  border-radius: 50%;
  opacity: 1;

  position: absolute;
}

.profile span {
  top: 344px;
  left: 120px;
  width: 99px;
  height: 19px;
  text-align: left;
  font: normal normal normal 14px/19px Avenir;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  position: absolute;
  color: #fff;
}

/*---------------------------------------------Video Fetch-------------------------------------------------*/

.util {
  padding-top: 10px;
  background-color: #000000;
  display: flex;
  /* margin: 20px; */
  justify-content: space-between;
}
.util > button {
  margin: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
  text-decoration: underline;
  border: hidden;
  background-color: #000000;
  font: normal normal medium 16px/22px Avenir;
  letter-spacing: 0px;
  color: #c4b4f8;
  opacity: 1;
  cursor: pointer;
}

.Vid {
  background: #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.F-img {
  width: 240px;
  height: 150px;
  gap: 50px;
}

.F-images {
  height: 100%;
  width: 100%;
  margin: 0 10px;
}
/*----------------------------------------crousal----------------------------------*/
.view-less {
  display: flex;
  align-items: center;
  background-color: #000;
  justify-content: space-evenly;
  padding-right: 5px;
  gap: 5px;
  flex-wrap: wrap;
}

.videoDiv {
  position: relative;
  padding: 5px;
}

.small-video-title {
  color: white;
  position: absolute;
  top: 90px;
  padding-left: 15px;
}

.small-video-data {
  display: flex;
  gap: 5px;
  color: #cac9c9;
  position: absolute;
  top: 105px;
  padding-left: 15px;
  font-size: 10px;
}

.small-video-publisher-img {
  position: absolute;
  top: 110px;
  padding-left: 210px;
}

.small-video-publisher-img > img {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.small-video-pause-btn {
  color: white;
  position: absolute;
  top: 10px;
  right: 0px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #a7a3a3;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.small-video-pause-btn > i {
  height: 15px;
  width: 15px;
  margin: 0 auto;
  padding-left: 7px;
  padding-bottom: 2px;
}

.view-more {
  display: flex;
  background-color: #000;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 15px 0;
}

.search-page {
  min-height: 100vh;
  background-color: #000;
}

.searched-video {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.play-video {
  background-color: #0f121ff5;
}

.videos {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #000;
}

.suggestion-videos {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main-video {
  min-height: calc(50vh - 60px);
  max-width: 70vw;
  padding-top: 30px;
}

.main-video > video {
  height: 80%;
  width: 100%;
  border-right: 1px solid #ccc;
}

.main-video-data {
  padding-left: 20px;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: space-between;
  color: #bebbbb;
  font-size: 13px;
}

.main-video-publisher {
  display: flex;
  align-items: center;
  color: white;
  gap: 15px;
}

.main-video-publisher-img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.my-vid {
  display: flex;
  background-color: #000;
}

.my-vid .left {
  flex: 1;
  border-right: 1px solid white;
}

.my-videos {
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.my-video {
  padding-bottom: 15px;
  cursor: pointer;
}

.my-vid .right {
  flex: 1;
  border-left: 1px solid white;
  padding: 10px;
}

.my-vid .right > video {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.my-video-data {
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-bottom: 10px; */
  border-bottom: 0.5px solid #918989;
  padding: 10px;
}

.my-video-title {
  background-color: transparent;
  color: white;
  border: 1px solid #0f121ff5;
  font-size: 30px;
  outline: none;
}

.my-video-information {
  display: flex;
  color: #dfd9d9;
  font-size: 12px;
  gap: 20px;
}

.my-video-description {
  color: #d1cece;
  padding: 10px;
  padding-bottom: 30px;
  border-bottom: 1px solid #918989;
}

.my-video-description > textarea {
  width: 100%;
  height: 100px;
  border-radius: 5px;
  background-color: transparent;
  color: #d1cece;
  outline: none;
  border: none;
}

.my-video-buttons {
  padding-top: 100px;
  display: flex;
  justify-content: space-around;
}

.deleteBtn,
.saveBtn {
  padding: 10px 50px;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  font-weight: 700;
}

.deleteBtn {
  background-color: #f43d3d;
  border: 1px solid #f43d3d;
}

.saveBtn {
  background-color: #c4b4f8;
  border: 1px solid #c4b4f8;
}
.deleteBtn:disabled {
  cursor: not-allowed;
}

.saveBtn:disabled {
  cursor: not-allowed;
}
.msg{
  text-align: center;
  padding: 40px;
  color: salmon;
}
.sug{
  color: white;
  margin: 10px;
  text-decoration: underline;
  padding: 0;
}
.pagenot{
  background-image: url('https://clipground.com/images/img_tree-png-1.png');
    background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: xx-large;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .video-title{
    top: 20%;
    font-size: medium;
  }
  .video-data{
    top: 40%;
  }
  .user-links{
    font-size:small;
  }
  .V-container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
    width: 80%;
  }
  .V-container2>input{
    margin-left: 0;
  }
  .drag-drop{
    padding: 0;
    width: 90%;
  }
  .abc{
    width: 80%;
  }
  .abc input{
    margin-left: 0;
  }
  .abc textarea{
   margin-left: 0; 
  }
  .V-Title{
    width: 100%;
  }
  .videos{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* height: 30%; */
  }
  .main-video{
    width: 100%;
  }
  .main-video-title h2{
    font-size: small;
  }
  .my-vid{
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
  }
  .right{
    width: 100%;
  }
  .my-video-data input{
    width: 40%;
  }
  .my-video-information{
    margin-left: 0;
  }
  .container{
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .div1{
    height: 50vh;
    width: 100vw;
  }
  .title{
    margin-top: 0;
  }
  .title p{
    font-size: medium;
  }
/* .title a{
  margin-top: -10rem;
} */
.p3{
  margin-bottom: 0;
}
.title a{
margin-top: 5px;
font-size: large;
}
.p1{
  margin-top: 10%;
}
  .div2{
    /* margin-top:20% ; */
    height: 60vh;
    width: 100vw;
  }

  
}